@value secondary-font, min-small from from "@/styles/variables.module.css";

.container {
  display: flex;
  row-gap: 39px;
  flex-direction: column;
  align-items: center;
  background-color: #131313;
  padding: 48px 0;
  width: 100%;
}

.socialIcons {
  display: flex;
  column-gap: 24px;
  justify-content: center;
  align-items: center;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  color: #fff;
  font-weight: lighter;
  font-size: 16px;
  line-height: 20px;
  font-family: secondary-font;

  @media min-small {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 89px;
  }
}

.link:hover {
  color: #fff;
}
