@font-face {
  font-weight: 600;
  src:
    url(/assets/fonts/Oxygen-Bold.woff) format('woff'),
    url(/assets/fonts/Oxygen-Bold.woff2) format('woff2');
  font-family: Oxygen;
}

@font-face {
  font-weight: 200;
  src:
    url(/assets/fonts/Oxygen-Light.woff) format('woff'),
    url(/assets/fonts/Oxygen-Light.woff2) format('woff2');
  font-family: Oxygen;
}

@font-face {
  font-weight: 400;
  src:
    url(/assets/fonts/Oxygen-Regular.woff) format('woff'),
    url(/assets/fonts/Oxygen-Regular.woff2) format('woff2');
  font-family: Oxygen;
}

@font-face {
  font-weight: 400;
  src:
    url(/assets/fonts/DMSerifDisplay-Regular.woff) format('woff'),
    url(/assets/fonts/DMSerifDisplay-Regular.woff) format('woff2');
  font-family: DMSerifDisplay;
}

@font-face {
  font-weight: 600;
  src:
    url(/assets/fonts/DMSerifDisplay-Regular.woff) format('woff'),
    url(/assets/fonts/DMSerifDisplay-Regular.woff) format('woff2');
  font-family: DMSerifDisplay;
}

/* New Fonts */
@font-face {
  font-weight: 400;
  src: url(/assets/fonts/Ogg-Roman.ttf);
  font-family: Ogg;
}

@font-face {
  font-weight: 300;
  src: url(/assets/fonts/WorkSans-Light.ttf);
  font-family: Work Sans;
}

@font-face {
  font-weight: 400;
  src: url(/assets/fonts/WorkSans-Regular.ttf);
  font-family: Work Sans;
}

@font-face {
  font-weight: 500;
  src: url(/assets/fonts/WorkSans-Medium.ttf);
  font-family: Work Sans;
}

@font-face {
  font-weight: 600;
  src: url(/assets/fonts/WorkSans-SemiBold.ttf);
  font-family: Work Sans;
}

@font-face {
  font-weight: 400;
  src:
    url(/assets/fonts/Rollerscript-Smooth.woff) format('woff'),
    url(/assets/fonts/Rollerscript-Smooth.woff2) format('woff2');
  font-family: Roller Script;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family:
    Oxygen,
    DMSerifDisplay,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: light) {
  html {
    color-scheme: light;
  }
  body {
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
  }
}

.ant-upload-list {
  display: none !important;
}

.ant-select-selector {
  height: 50px !important;
  line-height: 50px;
}

.ant-select-selection-item {
  line-height: 50px !important;
}

.ant-select-selection-placeholder {
  line-height: 50px !important;
}

.ant-select-item-option:hover {
  background-color: #d9d9d9;
}

.ant-select-item-option-selected > .ant-select-item-option-content {
  color: #ffff;
}

.ant-input-affix-wrapper-disabled {
  background-color: #ffffff;
}
.ant-select-item-option:hover {
  background-color: #d9d9d9;
}

.ant-select-item-option-selected {
  background-color: #000000 !important;
}

.ant-select-item-option-selected > .ant-select-item-option-content {
  color: #ffff;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #d9d9d9 !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  border-radius: 50%;
  background-color: #000000 !important;
  color: #fff;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #003b93 !important;
  border-radius: 50%;
}

.ant-select-arrow {
  color: #000000;
}

.ant-modal-confirm-btns {
  display: none;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  box-shadow: none !important;
  border-color: #d9d9d9 !important;
}

#nprogress .bar {
  background: #ff92a5 !important;
}

.ant-input-affix-wrapper > input.ant-input {
  border: unset !important;
}

.tag-multiselect__popover {
  .ant-popover-inner-content {
    padding: 0;
    max-height: 500px;
    overflow-y: scroll;
  }
}

/* tooltip styling */
.pink-tooltip .ant-tooltip-inner {
  border-radius: 8px;
  background-color: #f0e4e1;
  padding: 12px;
  color: #803026;
}
.pink-tooltip .ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  border-bottom-color: #f0e4e1;
  background-color: #f0e4e1;
}
.pink-tooltip .ant-tooltip-arrow-content {
  --antd-arrow-background-color: #f0e4e1;
}

.heading--5 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #000;
  font-weight: 400;
  font-size: calc(20px + (400vw - 1280px) / 1080);
  line-height: 1.2;
}

input:focus {
  outline-color: initial;
  outline-style: initial;
  outline-width: none;
  border: 1px solid #000;
}

.yarl__slide.yarl__slide {
  padding: 0;
}
